.root {
    --msg-list__background: #faf9f7;
    --msg--hover__background: #f4f3f2;
    --msg__author__fontSize: 14px;
    --msg__title__margin: 0 0 5px;
    --msg__bubble__fontSize: 14px;
    --msg__bubble__color: black;
}

.root :global .pn-msg-list {
    border-radius: 4px;
    box-shadow: inset 0 0 3px 0px #cbcbcb;
}

/*
    Pubnub при "MessageList.messageRenderer={() => <></>}" оставляет пустые элементы,
    у которых есть padding, что в UI выглядет как непонятные пробелы между сообщениями,
    поэтому мы тут находим такие пустые элементы и скрываем
*/
.root :global .pn-msg-list-scroller > .pn-msg:not(:has(.pn-msg__main)) {
    display: none;
}
